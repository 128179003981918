/* Cores gerais */
export const fundo = "#F5F5F5";
export const corBorda = "#D5D6D7";
export const fundoTopo = "#3F8ED4";

/* Cores Login */
export const fundoLogin = "#F5F5F5";
export const fundoPainelLogin = "#FFF";
export const corBordaLogin = "#C0C0C0";
export const corSombraBordaLogin = "#A0A0A0";

/* Cores Menu */
export const fundoMenu = "#E4E4E4";
export const fundoMenuHover = "#F7F7F7"
export const fundoMenuAtivo = "#D7D7D7"
export const corTextoMenu = "#6B6B6B";
export const corTextoMenuHover = "#CCCCCC";
export const corTextoMenuAtivo = "#3F8ED4";

/* Painel */
export const corFundoPainel = "#FFF";
export const corTituloPainel = "#585858"
export const corLinhaPainel = "#A2A2A2";

/* Message */
export const messageError = "red";
export const messageWarn = "orange";
export const messageSuccess = "green";
export const messageInfo = "lightblue";
export const messageNormal = "#666";

/* IconButton */
export const iconButtonEnabled = "#666";
export const iconButtonDisabled = "#AAA";