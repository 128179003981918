import api from '../api'

export async function login(payload) {
  return api.post('user/login', payload);
}

export async function refreshToken(payload) {
  return api.post('user/refreshToken', payload);
}

export async function storeLogin(loginData) {
  localStorage.setItem("user", JSON.stringify(loginData));
  return true;
}

export async function doLogout() {
  localStorage.removeItem("user");
  return true;
}

export async function getLogin() {
  var user = localStorage.getItem("user");
  try {
    return JSON.parse(user);
  } catch {
    return undefined;
  }
}