import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import Login from './pages/login';
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import ListProduction from './pages/listProduction';
import { GlobalStyle } from './styles/global';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <BrowserRouter>
    <ToastContainer />
    <GlobalStyle />
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/production" element={<ListProduction />} />
      <Route path="*" element={<ListProduction />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

