import axios from 'axios';
import { getLogin, refreshToken, storeLogin } from './user';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  timeout: 30000
});

api.interceptors.request.use(
  async config => {
    if (config.url === "user/login") {
        return config;
    }

    try {
        var login = await getLogin();
        var token = login?.token;
        
        if (token) {
            config.headers.authorization = `Bearer ${token}`;      
        }
    } catch {

    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  }, 
  async function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401 :
          //try refresh token
          const originalRequest = error.config;
          let isRefreshRequest = false;
          if (originalRequest.url) {
            if (originalRequest.url.toLowerCase().startsWith('user/refreshtoken')) {
              isRefreshRequest = true;
            }
          }
          if (!originalRequest._retry && !isRefreshRequest) {
            var login = await getLogin();
            if (!login) {
              localStorage.clear();
              window.location.replace('/login');
            }

            const userToken = {
              accessToken: login.token,
              refreshToken: login.refreshToken
            }
            if (userToken.accessToken && userToken.refreshToken) {
              const newTokens = await refreshToken(userToken);
              if (newTokens.data) {
                storeLogin(newTokens.data);
                
                originalRequest.headers.authorization = `Bearer ${newTokens.data.token}`;
                originalRequest._retry = true;
    
                return axios(originalRequest);
              } else {
                localStorage.clear();
                window.location.replace('/login');
              }
            }
          }

          localStorage.clear();
          window.location.replace('/login');
          break
        default :
          break
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;