import { toast } from "react-toastify";

class ToastFactory {
    info(message) {
        toast.info(message);
    }

    error(message) {
        toast.error(message);
    }

    success(message) {
        toast.success(message);
    }
}

export default new ToastFactory();