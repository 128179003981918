import React, { useState } from 'react';
import { login, storeLogin } from '../../services/user';
import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import { Container, Content } from './styled';
import { Grid } from '@mui/material';
import logo from '../../assets/images/logo.png'
import ToastFactory from '../../services/toast'

function Login() {
  const [fieldLogin, setFieldLogin] = useState();
  const [fieldPassword, setFieldPassword] = useState();

  const doLogin = () => {
    var user = {
      login: fieldLogin,
      password: fieldPassword,
    };

    login(user).then(data => {
      storeLogin(data.data);

      window.location.replace('/production');
    }).catch(ex => {
      ToastFactory.error('Usuário ou senha inválidos');
      console.log(ex);
    })
  };

  return (
    <Container>
      <Content>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img src={logo} alt="Premix" />
            <TextField id="outlined-basic"
              fullWidth
              label="Usuário"
              variant="outlined"
              type="text"
              onChange={(event) => setFieldLogin(event.target.value)}
              required 
            />
          </Grid>
          <Grid item xs={12}>
            <TextField id="outlined-basic"
              fullWidth
              label="Senha"
              variant="outlined"
              type="password"
              onChange={(event) => setFieldPassword(event.target.value)}
              required />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={doLogin}>Login</Button>
          </Grid>
        </Grid>
      </Content>
    </Container>
  );
}

export default Login;