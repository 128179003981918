import styled from 'styled-components';
import * as cor from '../../styles/colors';

export const Container = styled.div`
  height: 100%;
  background: ${cor.fundoLogin};
  background-size: 29px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Content = styled.div`
  width: 90%;
  max-width: 450px;
  text-align: center;
  background-color: ${cor.fundoPainelLogin};
  padding: 25px 18px;
  border: solid ${cor.corBordaLogin} 1px;
  box-shadow: 3px 3px 6px ${cor.corSombraBordaLogin};  

  img {
    align: center;
    width: 70%;
  }
`