import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: #FFF;
    font-family: 'Roboto', 'sans-serif';
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    font: 14px Roboto, sans-serif;
  }

  html, body, #root {
    height: 100%;
  }

  button {
    cursor: pointer;
  }

  ::-webkit-scrollbar {
    width:6px
  }
  ::-webkit-scrollbar-track {
    background:#ddd;
    border-radius:12px;
  }
  ::-webkit-scrollbar-thumb {
    background:#666;
    border-radius:12px;
  }
`;