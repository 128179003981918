import { Table } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableCell } from "@mui/material";
import { Paper } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableContainer } from "@mui/material";
import { useEffect, useState } from "react";
import { getProductions, updateProduction } from "../../services/production";
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton } from "@mui/material";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";
import { Checkbox } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Button } from "@mui/material";
import { AppBar } from "@mui/material";
import { Toolbar } from "@mui/material";
import { Typography } from "@mui/material";
import logo from '../../assets/images/logo-branca.png'
import { Container } from "@mui/material";
import { Grid } from "@mui/material";
import { doLogout } from "../../services/user";
import ToastFactory from "../../services/toast";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  backgroundColor: 'white'
};

function ListProduction() {
  const [open, setOpen] = useState(false);
  const [productionEdit, setProductionEdit] = useState(false);
  const [productionEditQuantidadeSacos, setProductionEditQuantidadeSacos] = useState(false);
  const [productionEditNaoConformidade, setProductionEditNaoConformidade] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const handleClose = () => {
    setProductionEdit(undefined);
    setProductionEditQuantidadeSacos(undefined);
    setProductionEditNaoConformidade(undefined);
    setOpen(false);
    setRefresh(refresh + 1);
  }

  const handleEdit = (production) => {
    setProductionEdit(production);
    setProductionEditQuantidadeSacos(production.QuantidadeSacos || 0);
    setProductionEditNaoConformidade(production.NaoConformidade || false);
    setOpen(true);
  }

  const [productions, setProductions] = useState([]);

  useEffect(() => {
    getProductions().then(data => {
      console.log('suesso')
      setProductions(data.data);
    }).catch(err => {
      ToastFactory.error('Ocorreu um erro ao obter as OPs!');
    });
  }, [refresh]);

  function saveQty() {
    //Checa quantidade de sacos
    if (!productionEditQuantidadeSacos || productionEditQuantidadeSacos === 0) {
      ToastFactory.info('Informe a quantidade de sacos');     
      return; 
    }
    if (!productionEditNaoConformidade) {
      const percVariacao = Math.abs(((productionEditQuantidadeSacos / productionEdit.QtdSacosDesejados) - 1)).toFixed(4)
      if (percVariacao > 0.02) {
        ToastFactory.error('A quantidade de sacos produzidas não pode variar mais que 2% da quantidade esperada');
        return;
      }
    }

    var payload = {
      Filial: productionEdit.Filial,
      CodOP: productionEdit.CodOP,
      NaoConformidade: productionEditNaoConformidade,
      QuantidadeSacos: productionEditQuantidadeSacos
    }

    updateProduction(payload).then(data => {
      ToastFactory.success('Quantidade apontada com sucesso!');
      handleClose();
    }).catch(err => {
      ToastFactory.success('Ocorreu um erro ao atualizar a quantidade!');
    })
  }

  function handleLogout() {
    doLogout();
    window.location.replace('/login');
  }

  function masterDetail(title, value, size = 12) {
    return (
      <Grid item xs={size}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <b>{title}: </b>
          </Grid>
          <Grid item xs={12}>
            {value}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const textStyle = {
    marginTop: "10px",
    marginBottom: "10px"
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar variant="dense">
          <img src={logo} alt="Premix" width="150" />
          <Typography variant="h6" color="inherit" component="div">
            Apontamento de produção
          </Typography>
          <Button variant="contained" style={{backgroundColor: '#EEE', color: '#1976d2', marginLeft: 'auto'}} onClick={handleLogout}>Sair</Button>
        </Toolbar>
      </AppBar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            {masterDetail("Filial", productionEdit?.Filial, 6)}
            {masterDetail("Código OP", productionEdit?.CodOP, 6)}
            {masterDetail("Produto", productionEdit?.CodProduto, 3)}
            {masterDetail("Descrição", productionEdit?.DescProduto, 9)}
            {masterDetail("Peso por Batida", productionEdit?.Quantidade, 6)}
            {masterDetail("Qtd Batida", productionEdit?.QuantidadeBateladas, 6)}
            
            <Grid item xs={5}>
              <TextField id="outlined-basic"
                label="Qtd Sacos"
                variant="outlined"
                  type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={productionEditQuantidadeSacos}
                onChange={(event) => setProductionEditQuantidadeSacos(event.target.value)}
                required
              />
            </Grid>


            <Grid item xs={7}>
              <FormControlLabel
                control={
                  <Checkbox checked={productionEditNaoConformidade} name="NaoConformidade" onClick={() => setProductionEditNaoConformidade(!productionEditNaoConformidade)} />
                }
                label="Não Conformidade"
              />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="error" onClick={handleClose} style={{marginRight: '10px'}}>Cancelar</Button>
                <Button variant="contained" onClick={saveQty}>Salvar</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Container maxWidth="xl">
        <Grid container spacing={0}>
          <Grid item xs={10}>
            <Typography variant="h5" color="inherit" component="div" style={textStyle}>
              Produções pendentes
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={() => setRefresh(refresh + 1)}><RefreshIcon/></IconButton>
            </Box>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Filial</TableCell>
                <TableCell align="right">Codigo OP</TableCell>
                <TableCell align="right">Produto</TableCell>
                <TableCell align="right">Descrição</TableCell>
                <TableCell align="right">Peso por Batida</TableCell>
                <TableCell align="right">Qtd Batida</TableCell>
                <TableCell align="right">Qtd Sacos</TableCell>
                <TableCell align="right">Nao conformidade</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productions.map((row) => (
                <TableRow
                  key={row.CodOP}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.Filial}
                  </TableCell>
                  <TableCell align="right">{row.CodOP}</TableCell>
                  <TableCell>{row.CodProduto}</TableCell>
                  <TableCell>{row.DescProduto}</TableCell>
                  <TableCell align="right">{row.Quantidade}</TableCell>
                  <TableCell align="right">{row.QuantidadeBateladas}</TableCell>
                  <TableCell align="right">{row.QuantidadeSacos}</TableCell>
                  <TableCell align="center">{row.NaoConformidade ? 'Sim' : 'Não'}</TableCell>
                  <TableCell align="center"><IconButton onClick={() => handleEdit(row)}><EditIcon/></IconButton></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}

export default ListProduction;
